<template>
  <div class="manage">
     <!-- 顶部图片 -->
    <div class="imgs">
      <img src="../../assets/imgs/banner-5.jpg" alt="">
    </div>
    <div class="w content">
      <div class="protect">
        <Successeactivity></Successeactivity>
      </div>
    </div>
  </div>
</template>

<script>
import Successeactivity from "@/components/successeactivity";
export default {
  data() {
    return {
      num: '',
    };
  },
   mounted () {
      this.$store.commit('ip/SET_routefullPath',this.$route.fullPath)
      this.num = this.$route.query.num
  },
  methods: {
    addNum(i,val){
        this.num = val
        this.$router.push({name:val,query:{num:val}})
    }  
  },
  components: {
    Successeactivity
  },
};
</script>

<style lang="scss" scoped>
.imgs{
  width: 100%;
  img{
    width: 100%;
    height: 100%;
  }
}
.nav {
  height: 63px;
  line-height: 60px;
  background-color: #fbfbfb;
  border: solid 1px #ededed;
  margin-bottom: 10px;
  ul {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    li {
      min-width: 100px;
      font-size: 20px;
      color: #666666;
      box-sizing: border-box;
    }
    li:nth-child(2) {
      margin: 0 50px;
    }
    .active {
      border-bottom: 3px solid #e43838;
      color: #333333;
    }
  }
}
.content {
  .protect {
    margin-bottom: 10px;
  }
  .service {
    margin-bottom: 10px;
  }
  .manage {
    margin-bottom: 10px;
  }
}
</style>